function drawRectangleCustomer(rectangle, isSelectedObject) {
  var xPos = getXPos(rectangle.x);
  var yPos = getYPos(rectangle.y);
  var width = getWidth(rectangle.width);
  var height = getHeight(rectangle.height);

  initRotationCustomer(xPos, yPos, rectangle.rotation);

  if (rectangle.filled) {
    context.fillStyle = rectangle.fillColor;
    context.fillRect(xPos, yPos, width, height);
  }

  if (rectangle.hasBorder && !isSelectedObject) {
    context.strokeStyle = rectangle.borderColor;
    context.lineWidth = rectangle.borderWidth;
    context.strokeRect(xPos, yPos, width, height);
  } else if (isSelectedObject) {
    context.strokeStyle = 'red';
    context.lineWidth = 2;
    context.strokeRect(xPos, yPos, width, height);
  }

  endRotationCustomer();
}
