function initButtonFunctions() {
  initImportFunction();
  initExportFunction();
  initSetDefaultsFunction();
  initCopyFunction();
  initDeleteFunction();
}

function initImportFunction() {
  document.getElementById('importButton').addEventListener('click', importMap);
}

function initExportFunction() {
  document.getElementById('exportButton').addEventListener('click', exportMap);
}

function initSetDefaultsFunction() {
  document.getElementById('setDefaultsButton').addEventListener('click', setDefaults);
}

function initCopyFunction() {
  document.getElementById('copyButton').addEventListener('click', copyObject);
}

function initDeleteFunction() {
  document.getElementById('deleteButton').addEventListener('click', deleteSelectedObject);
}

function importMap() {
  const filePicker = document.createElement('input');
  filePicker.setAttribute('type', 'file');
  filePicker.setAttribute('accept', '.json');
  filePicker.addEventListener('change', event => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = event => {
      const json = JSON.parse(event.target.result);

      objectData = json;
      if (objectData.length > 0) {
        selectedObjectIndex = 0;
      } else {
        selectedObjectIndex = -1;
      }
      redrawMap();
    };

    fileReader.readAsText(selectedFile, 'utf-8');
  });

  filePicker.click();
}

function exportMap() {
  const blob = new Blob([JSON.stringify(objectData, null, 2)], { type: 'application/json' });
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', URL.createObjectURL(blob));
  downloadLink.setAttribute('download', 'data.json');
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

function setDefaults() {
  mapDefaults = {
    x: mapPositionX,
    y: mapPositionY,
    zoom: zoomingFactor
  };
}

function copyObject() {
  document.getElementById('pasteObject').style.display = 'block';
  copiedElementIndex = selectedObjectIndex;
}

function deleteSelectedObject() {
  objectData.splice(selectedObjectIndex, 1);
  selectedObjectIndex = -1;
  redrawMap();
}
