function drawLineCustomer(line, isSelectedObject) {
  var xPos = getXPos(line.x);
  var yPos = getYPos(line.y);

  var length = getLength(line.length);

  initRotationCustomer(xPos, yPos, line.rotation);

  context.lineWidth = line.width;
  context.beginPath();
  context.moveTo(xPos, yPos);

  if (!isSelectedObject) {
    context.strokeStyle = line.color;
  } else if (isSelectedObject) {
    context.strokeStyle = 'red';
  }

  context.lineTo(xPos + length, yPos);
  context.stroke();

  endRotationCustomer();
}
