let isMousePressedAdmin = false;
let startXAdmin = 0;
let startYAdmin = 0;
let distanceAdmin = 0;

function initMoving(){
    canvasAdmin.addEventListener('mousedown', handleMouseDown);
    canvasAdmin.addEventListener('mousemove', handleMouseMove);
    canvasAdmin.addEventListener('mouseup', handleMouseUp);
}

function handleMouseDown(event) {
    if (event.button === 0) {
        isMousePressedAdmin = true;
        startXAdmin = event.offsetX;
        startYAdmin = event.offsetY;
        distanceAdmin = 0;
    }
}

function handleMouseMove(event) {
    if (isMousePressedAdmin  && mapMovingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        mapPositionX += dx;
        mapPositionY += dy;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function handleMouseUp(event) {
    if (event.button === 0 && isMousePressedAdmin) {
        isMousePressedAdmin = false;
    }
}
