function initObjectInfoSection() {
  document.getElementById('objectInfoContainer').style.display = 'block';

  var selectedObject = objectData[selectedObjectIndex];

  disableAll();

  if (selectedObjectIndex !== -1) {
    document.getElementById('copyButton').style.display = 'block';
    document.getElementById('deleteButton').style.display = 'block';

    if (selectedObject.type === 'RECTANGLE') {
      initObjectName();
      initObjectRotation();
      initObjectXPos();
      initObjectYPos();
      initObjectHeight();
      initObjectWidth();
      initObjectBorderWidth();
      initObjectBorderColor();
      initObjectShowBorder();
      initObjectFillObject();
      initObjectFillColor();
    } else if (selectedObject.type === 'LINE') {
      initObjectName();
      initObjectRotation();
      initObjectXPos();
      initObjectYPos();
      initObjectWidth();
      initObjectLenght();
      initObjectLineColor();
    } else if (selectedObject.type === 'SECTION') {
      initObjectName();
      initObjectRotation();
      initObjectXPos();
      initObjectYPos();
      initObjectHeight();
      initObjectWidth();
      initObjectBorderWidth();
      initObjectBorderColor();
      initObjectShowBorder();
      initObjectFillObject();
      initObjectFillColor();
      initObjectFreeSeatSelection();
      initObjectShowRowNumbers();
      initObjectSelectPriceConfig();
      if (selectedObject.freeSeatSelection) {
        initObjectSeats();
      } else {
        initObjectSeatColor();
        initObjectSeatSize();
        initObjectRows();
        initObjectRowSeats();
      }
    } else if (selectedObject.type === 'TEXT') {
      initObjectName();
      initObjectText();
      initObjectRotation();
      initObjectXPos();
      initObjectYPos();
      initObjectTextColor();
      initObjectFontSize();
    }
  }
}

function disableAll() {
  document.getElementById('mapObjectNameContainer').style.display = 'none';
  document.getElementById('mapObjectTextContainer').style.display = 'none';
  document.getElementById('mapObjectRotationContainer').style.display = 'none';
  document.getElementById('mapObjectXPosContainer').style.display = 'none';
  document.getElementById('mapObjectYPosContainer').style.display = 'none';
  document.getElementById('mapObjectHeightContainer').style.display = 'none';
  document.getElementById('mapObjectWidthContainer').style.display = 'none';
  document.getElementById('mapObjectLengthContainer').style.display = 'none';
  document.getElementById('mapObjectBorderWidthContainer').style.display = 'none';
  document.getElementById('mapObjectBorderColorContainer').style.display = 'none';
  document.getElementById('mapObjectShowBorderContainer').style.display = 'none';
  document.getElementById('mapObjectFillObjectContainer').style.display = 'none';
  document.getElementById('mapObjectFillColorContainer').style.display = 'none';
  document.getElementById('mapObjectLineColorContainer').style.display = 'none';
  document.getElementById('mapObjectTextColorContainer').style.display = 'none';
  document.getElementById('mapObjectFontSizeContainer').style.display = 'none';
  document.getElementById('freeSeatSelectionContainer').style.display = 'none';
  document.getElementById('seatsContainer').style.display = 'none';
  document.getElementById('mapObjectRowsContainer').style.display = 'none';
  document.getElementById('mapObjectRowSeatsContainer').style.display = 'none';
  document.getElementById('mapObjectSeatColorContainer').style.display = 'none';
  document.getElementById('mapObjectSeatSizeContainer').style.display = 'none';
  document.getElementById('mapObjectShowRowNumbersContainer').style.display = 'none';
  document.getElementById('mapObjectPriceConfigContainer').style.display = 'none';
  document.getElementById('copyButton').style.display = 'none';
  document.getElementById('deleteButton').style.display = 'none';
}

function initObjectName() {
  document.getElementById('mapObjectNameContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectName');
  inputElem.value = selectedObject.name;
  inputElem.addEventListener('input', updateObjectName);
}

function updateObjectName() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectName');
  selectedObject.name = inputElem.value;
  initObjectSelectionMenu();
}

function initObjectText() {
  document.getElementById('mapObjectTextContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectText');
  inputElem.value = selectedObject.text;
  inputElem.addEventListener('input', updateObjectText);
}

function updateObjectText() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectText');
  selectedObject.text = inputElem.value;
  redrawMap();
}

function initObjectRotation() {
  document.getElementById('mapObjectRotationContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectRotation');
  inputElem.value = selectedObject.rotation;
  inputElem.addEventListener('change', updateObjectRotation);
}

function updateObjectRotation() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectRotation');
  selectedObject.rotation = parseInt(inputElem.value);
  redrawMap();
}

function initObjectXPos() {
  document.getElementById('mapObjectXPosContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectXPos');
  inputElem.value = selectedObject.x;
  inputElem.addEventListener('change', updateObjectXPos);
}

function updateObjectXPos() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectXPos');
  selectedObject.x = parseInt(inputElem.value);
  redrawMap();
}

function initObjectYPos() {
  document.getElementById('mapObjectYPosContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectYPos');
  inputElem.value = selectedObject.y;
  inputElem.addEventListener('change', updateObjectYPos);
}

function updateObjectYPos() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectYPos');
  selectedObject.y = parseInt(inputElem.value);
  redrawMap();
}

function initObjectHeight() {
  document.getElementById('mapObjectHeightContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectHeight');
  inputElem.value = selectedObject.height;
  inputElem.addEventListener('change', updateObjectHeight);
}

function updateObjectHeight() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectHeight');
  selectedObject.height = parseInt(inputElem.value);
  redrawMap();
}

function initObjectWidth() {
  document.getElementById('mapObjectWidthContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectWidth');
  inputElem.value = selectedObject.width;
  inputElem.addEventListener('change', updateObjectWidth);
}

function updateObjectWidth() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectWidth');
  selectedObject.width = parseInt(inputElem.value);
  redrawMap();
}

function initObjectLenght() {
  document.getElementById('mapObjectLengthContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectLength');
  inputElem.value = selectedObject.length;
  inputElem.addEventListener('change', updateObjectLength);
}

function updateObjectLength() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectLength');
  selectedObject.length = parseInt(inputElem.value);
  redrawMap();
}

function initObjectBorderWidth() {
  document.getElementById('mapObjectBorderWidthContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectBorderWidth');
  inputElem.value = selectedObject.borderWidth;
  inputElem.addEventListener('change', updateObjectBorderWidth);
}

function updateObjectBorderWidth() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectBorderWidth');
  selectedObject.borderWidth = parseInt(inputElem.value);
  redrawMap();
}

function initObjectBorderColor() {
  document.getElementById('mapObjectBorderColorContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectBorderColor');
  inputElem.value = selectedObject.borderColor;
  inputElem.addEventListener('change', updateObjectBorderColor);
}

function updateObjectBorderColor() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectBorderColor');
  selectedObject.borderColor = inputElem.value;
  redrawMap();
}

function initObjectShowBorder() {
  document.getElementById('mapObjectShowBorderContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectShowBorder');
  inputElem.checked = selectedObject.hasBorder;
  inputElem.addEventListener('change', updateObjectShowBorder);
}

function updateObjectShowBorder() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectShowBorder');
  selectedObject.hasBorder = inputElem.checked;
  redrawMap();
}

function initObjectFillObject() {
  document.getElementById('mapObjectFillObjectContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFillObject');
  inputElem.checked = selectedObject.filled;
  inputElem.addEventListener('change', updateObjectFillObject);
}

function updateObjectFillObject() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFillObject');
  selectedObject.filled = inputElem.checked;
  redrawMap();
}

function initObjectFillColor() {
  document.getElementById('mapObjectFillColorContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFillColor');
  inputElem.value = selectedObject.fillColor;
  inputElem.addEventListener('change', updateObjectFillColor);
}

function updateObjectFillColor() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFillColor');
  selectedObject.fillColor = inputElem.value;
  redrawMap();
}

function initObjectLineColor() {
  document.getElementById('mapObjectLineColorContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectLineColor');
  inputElem.value = selectedObject.color;
  inputElem.addEventListener('change', updateObjectLineColor);
}

function updateObjectLineColor() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectLineColor');
  selectedObject.color = inputElem.value;
  redrawMap();
}

function initObjectTextColor() {
  document.getElementById('mapObjectTextColorContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectTextColor');
  inputElem.value = selectedObject.color;
  inputElem.addEventListener('change', updateObjectTextColor);
}

function updateObjectTextColor() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectTextColor');
  selectedObject.color = inputElem.value;
  redrawMap();
}

function initObjectFontSize() {
  document.getElementById('mapObjectFontSizeContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFontSize');
  inputElem.value = selectedObject.fontSize;
  inputElem.addEventListener('change', updateObjectFontSize);
}

function updateObjectFontSize() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFontSize');
  selectedObject.fontSize = parseInt(inputElem.value);
  redrawMap();
}

function initObjectSeatColor() {
  document.getElementById('mapObjectSeatColorContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectSeatColor');
  inputElem.value = selectedObject.seatColor;
  inputElem.addEventListener('change', updateObjectSeatColor);
}

function updateObjectSeatColor() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectSeatColor');
  selectedObject.seatColor = inputElem.value;
  redrawMap();
}

function initObjectSeatSize() {
  document.getElementById('mapObjectSeatSizeContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectSeatSize');
  inputElem.value = selectedObject.seatSize;
  inputElem.addEventListener('change', updateObjectSeatSize);
}

function updateObjectSeatSize() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectSeatSize');
  selectedObject.seatSize = parseInt(inputElem.value);
  redrawMap();
}

function initObjectRows() {
  document.getElementById('mapObjectRowsContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectRows');
  inputElem.value = selectedObject.rows.length;
  inputElem.addEventListener('change', updateObjectRows);
}

function updateObjectRows() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectRows');
  var rowsCount = parseInt(inputElem.value);
  var rows = [];
  for (var i = 0; i < rowsCount; i++) {
    rows.push({ number: i + 1 });
  }
  selectedObject.rows = rows;
  updateObjectRowSeats();

  redrawMap();
}

function initObjectRowSeats() {
  document.getElementById('mapObjectRowSeatsContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];

  if (!selectedObject.rows) {
    selectedObject.rows = getDefaultRowSeats();
  }

  var inputElem = document.getElementById('mapObjectRowSeats');
  inputElem.value = selectedObject.rows[0] === undefined ? 0 : selectedObject.rows[0].seats.length;
  inputElem.addEventListener('change', updateObjectRowSeats);
}

function updateObjectRowSeats() {
  var selectedObject = objectData[selectedObjectIndex];

  var inputElem = document.getElementById('mapObjectRowSeats');
  var rowSeats = parseInt(inputElem.value);
  for (var i = 0; i < selectedObject.rows.length; i++) {
    var seats = [];
    for (var j = 0; j < rowSeats; j++) {
      seats.push({
        number: j + 1,
        free: true
      });
    }

    selectedObject.rows[i].seats = seats;
  }
  redrawMap();
}

function initObjectFreeSeatSelection() {
  document.getElementById('freeSeatSelectionContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFreeSeatSelection');
  inputElem.checked = selectedObject.freeSeatSelection;
  inputElem.addEventListener('change', updateObjectFreeSeatSelection);
}

function updateObjectFreeSeatSelection() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectFreeSeatSelection');
  selectedObject.freeSeatSelection = inputElem.checked;

  if (selectedObject.freeSeatSelection) {
    document.getElementById('mapObjectSeats').value = 20;
    updateObjectSeats();
    selectedObject.rows = null;
  } else {
    selectedObject.seats = null;
    selectedObject.rows = getDefaultRowSeats();
  }

  redrawMap();
}

function initObjectSeats() {
  document.getElementById('seatsContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectSeats');
  inputElem.value = selectedObject.seats.length;
  inputElem.addEventListener('change', updateObjectSeats);
}

function updateObjectSeats() {
  document.getElementById('seatsContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectSeats');
  var seats = [];
  for (let i = 0; i < inputElem.value; i++) {
    seats.push({
      number: i + 1
    });
  }
  selectedObject.seats = seats;
}

function initObjectShowRowNumbers() {
  document.getElementById('mapObjectShowRowNumbersContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectShowRowNumbers');
  inputElem.checked = selectedObject.showRowNumbers;
  inputElem.addEventListener('change', updateObjectShowRowNumbers);
}

function updateObjectShowRowNumbers() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectShowRowNumbers');
  selectedObject.showRowNumbers = inputElem.checked;
  redrawMap();
}

function initObjectSelectPriceConfig() {
  document.getElementById('mapObjectPriceConfigContainer').style.display = 'block';
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectPriceConfig');
  updateSelectedPriceConfig(parseInt(selectedObject.priceConfigId));
  inputElem.value = parseInt(selectedObject.priceConfigId);
  inputElem.addEventListener('change', updateObjectPriceConfig);
}

function updateObjectPriceConfig() {
  var selectedObject = objectData[selectedObjectIndex];
  var inputElem = document.getElementById('mapObjectPriceConfig');
  selectedObject.priceConfigId = parseInt(inputElem.value);
  redrawMap();
}
