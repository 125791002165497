function getInitialSection(priceConfigId) {
  return {
    type: 'SECTION',
    name: 'Section',
    x: canvasCenterXAdmin - 75,
    y: canvasCenterYAdmin - 50,
    rotation: 0,
    width: 150,
    height: 100,
    filled: false,
    hasBorder: false,
    fillColor: 'white',
    borderWidth: 2,
    borderColor: 'black',
    freeSeatSelection: false,
    seatSize: 20,
    seatColor: 'green',
    showRowNumbers: false,
    priceConfigId: priceConfigId,
    rows: [
      {
        number: 1,
        seats: [
          {
            number: 1
          },
          {
            number: 2
          },
          {
            number: 3
          },
          {
            number: 4
          },
          {
            number: 5
          },
          {
            number: 6
          }
        ]
      },
      {
        number: 2,
        seats: [
          {
            number: 1
          },
          {
            number: 2
          },
          {
            number: 3
          },
          {
            number: 4
          },
          {
            number: 5
          },
          {
            number: 6
          }
        ]
      },
      {
        number: 3,
        seats: [
          {
            number: 1
          },
          {
            number: 2
          },
          {
            number: 3
          },
          {
            number: 4
          },
          {
            number: 5
          },
          {
            number: 6
          }
        ]
      },
      {
        number: 4,
        seats: [
          {
            number: 1
          },
          {
            number: 2
          },
          {
            number: 3
          },
          {
            number: 4
          },
          {
            number: 5
          },
          {
            number: 6
          }
        ]
      }
    ]
  };
}

function getDefaultRowSeats() {
  return [
    {
      number: 1,
      seats: [
        {
          number: 1
        },
        {
          number: 2
        },
        {
          number: 3
        },
        {
          number: 4
        },
        {
          number: 5
        },
        {
          number: 6
        }
      ]
    },
    {
      number: 2,
      seats: [
        {
          number: 1
        },
        {
          number: 2
        },
        {
          number: 3
        },
        {
          number: 4
        },
        {
          number: 5
        },
        {
          number: 6
        }
      ]
    },
    {
      number: 3,
      seats: [
        {
          number: 1
        },
        {
          number: 2
        },
        {
          number: 3
        },
        {
          number: 4
        },
        {
          number: 5
        },
        {
          number: 6
        }
      ]
    },
    {
      number: 4,
      seats: [
        {
          number: 1
        },
        {
          number: 2
        },
        {
          number: 3
        },
        {
          number: 4
        },
        {
          number: 5
        },
        {
          number: 6
        }
      ]
    }
  ];
}
