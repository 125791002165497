function drawLine(line, isSelectedObject){
    var xPos = getXPos(line.x);
    var yPos = getYPos(line.y);

    var length = getLength(line.length);


    initRotation(xPos, yPos, line.rotation);

    contextAdmin.lineWidth = line.width;
    contextAdmin.beginPath();
    contextAdmin.moveTo(xPos, yPos);

    if(!isSelectedObject){
        contextAdmin.strokeStyle = line.color;
    }else if(isSelectedObject){
        contextAdmin.strokeStyle = "red";
    }

    contextAdmin.lineTo(xPos + length, yPos);
    contextAdmin.stroke();

    endRotation();
}
