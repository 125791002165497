function drawText(text, isSelectedObject){
    var xPos = getXPos(text.x);
    var yPos = getYPos(text.y);

    initRotation(xPos, yPos, text.rotation);

    var fontSize = text.fontSize * zoomingFactor;

    contextAdmin.font = fontSize + "px Arial";
    contextAdmin.fillStyle = isSelectedObject ? "red" : text.color;
    contextAdmin.strokeStyle = isSelectedObject ? "red" : text.color;

    contextAdmin.fillText(text.text, xPos, yPos);

    contextAdmin.strokeText(text.text, xPos, yPos);

    endRotation();
}
