function initZooming() {
    canvasAdmin.addEventListener('wheel', handleZoom);
}

function handleZoom(event) {
    if (mapZoomingActive) {
        event.preventDefault();
        const oldZoom = zoomingFactor;
        zoomingFactor = event.deltaY > 0 ? zoomingFactor / zoomSpeedAdmin : zoomingFactor * zoomSpeedAdmin;
        const newZoom = zoomingFactor;
        const mouseX = event.offsetX;
        const mouseY = event.offsetY;
        const fixX = (mouseX - mapPositionX) / oldZoom;
        const fixY = (mouseY - mapPositionY) / oldZoom;
        mapPositionX += fixX * (oldZoom - newZoom);
        mapPositionY += fixY * (oldZoom - newZoom);
        redrawMap();
    }
}
