function getXPos(x) {
  return mapPositionX + x * zoomingFactor;
}

function getYPos(y) {
  return mapPositionY + y * zoomingFactor;
}

function getWidth(width) {
  return width * zoomingFactor;
}

function getHeight(height) {
  return height * zoomingFactor;
}

function getLength(length) {
  return length * zoomingFactor;
}

function radiansToDegrees(radians) {
  return (radians * 180) / Math.PI;
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

function initRotationCustomer(pivotX, pivotY, degrees) {
  context.save();
  context.translate(pivotX, pivotY);
  context.rotate(degreesToRadians(degrees));
  context.translate(-pivotX, -pivotY);
}

function endRotationCustomer() {
  context.restore();
}

function isPointInsideRotatedObject(xClicked, yClicked, xPos, yPos, width, height, rotation) {
  var angleRad = degreesToRadians(-rotation);
  var cosAngle = Math.cos(angleRad);
  var sinAngle = Math.sin(angleRad);
  var xDiff = xClicked - xPos;
  var yDiff = yClicked - yPos;
  var rotatedX = xDiff * cosAngle - yDiff * sinAngle;
  var rotatedY = xDiff * sinAngle + yDiff * cosAngle;

  if (rotatedX >= 0 && rotatedX <= width && rotatedY >= 0 && rotatedY <= height) {
    return true;
  }

  return false;
}

function isPointInsideRotatedCircle(x, y, circleX, circleY, radius, rotation) {
  var angleRad = degreesToRadians(-rotation);
  var cosAngle = Math.cos(angleRad);
  var sinAngle = Math.sin(angleRad);

  var xDiff = x - circleX;
  var yDiff = y - circleY;
  var rotatedX = xDiff * cosAngle - yDiff * sinAngle;
  var rotatedY = xDiff * sinAngle + yDiff * cosAngle;

  var distanceFromCenter = Math.sqrt(rotatedX * rotatedX + rotatedY * rotatedY);
  return distanceFromCenter <= radius;
}
