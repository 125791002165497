var text_objectMovingActive = false;
var text_startXMoving = 0;
var text_startYMoving = 0;
var text_movingDistance = 0;

function initTextObjectOptions() {
    var selectedObject = objectData[selectedObjectIndex];
    if (selectedObject.rotation == 0) {
        addTextMoveIcon();
    }
}

function addTextMoveIcon() {
    var selectedObject = objectData[selectedObjectIndex];

    var xPos = getXPos(selectedObject.x) - 45;
    var yPos = getYPos(selectedObject.y);


    var image = new Image();
    image.onload = function () {
        contextAdmin.drawImage(image, xPos, yPos, 30, 30);
    };
    image.src = 'assets/modules/location_manager_admin/images/move_icon.png';

    canvasAdmin.addEventListener("mousedown", clickOnTextMoveIcon);
    canvasAdmin.addEventListener('mousemove', moveTextObject);
    canvasAdmin.addEventListener('mouseup', stopTextObjectMoving);
}

function clickOnTextMoveIcon(event) {
    if (selectedObjectIndex !== -1) {
        var selectedObject = objectData[selectedObjectIndex];
        var xPos = getXPos(selectedObject.x) - 45;
        var yPos = getYPos(selectedObject.y);

        if (event.offsetX > xPos && event.offsetX < xPos + 30) {
            if (event.offsetY > yPos && event.offsetY < yPos + 30) {
                mapZoomingActive = false;
                mapMovingActive = false;
                text_objectMovingActive = true;
                text_startXMoving = event.offsetX;
                text_startYMoving = event.offsetY;
                text_movingDistance = 0;
            }
        }
    }
}

function moveTextObject(event) {
    if (text_objectMovingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        var selectedObject = objectData[selectedObjectIndex];

        selectedObject.x += dx / zoomingFactor;
        selectedObject.y += dy / zoomingFactor;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function stopTextObjectMoving() {
    text_objectMovingActive = false;
    mapZoomingActive = true;
    mapMovingActive = true;
}
