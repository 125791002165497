function initZoomingCustomer() {
  canvas.addEventListener('wheel', handleZoomCustomer);

  var zoomInButton = document.getElementById('zoom_in_map_button');
  var zoomOutButton = document.getElementById('zoom_out_map_button');

  if (zoomInButton && zoomOutButton) {
    zoomInButton.addEventListener('click', handleZoomInCustomer);
    zoomOutButton.addEventListener('click', handleZoomOutCustomer);
  }
}

function handleZoomCustomer(event) {
  if (mapZoomingActive) {
    event.preventDefault();
    const oldZoom = zoomingFactor;
    zoomingFactor = event.deltaY > 0 ? Math.max(0.2, zoomingFactor / zoomSpeed) : Math.min(2, zoomingFactor * zoomSpeed);
    const newZoom = zoomingFactor;
    const mouseX = event.offsetX;
    const mouseY = event.offsetY;
    const fixX = (mouseX - mapPositionX) / oldZoom;
    const fixY = (mouseY - mapPositionY) / oldZoom;
    mapPositionX += fixX * (oldZoom - newZoom);
    mapPositionY += fixY * (oldZoom - newZoom);
    redrawMapCustomer();
  }
}

function handleZoomInCustomer() {
  const oldZoom = zoomingFactor;
  zoomingFactor = Math.min(2, zoomingFactor * zoomSpeed);
  const newZoom = zoomingFactor;
  const mouseX = event.offsetX;
  const mouseY = event.offsetY;
  const fixX = (mouseX - mapPositionX) / oldZoom;
  const fixY = (mouseY - mapPositionY) / oldZoom;
  mapPositionX += fixX * (oldZoom - newZoom);
  mapPositionY += fixY * (oldZoom - newZoom);
  redrawMapCustomer();
}

function handleZoomOutCustomer() {
  const oldZoom = zoomingFactor;
  zoomingFactor = Math.max(0.2, zoomingFactor / zoomSpeed);
  const newZoom = zoomingFactor;
  const mouseX = event.offsetX;
  const mouseY = event.offsetY;
  const fixX = (mouseX - mapPositionX) / oldZoom;
  const fixY = (mouseY - mapPositionY) / oldZoom;
  mapPositionX += fixX * (oldZoom - newZoom);
  mapPositionY += fixY * (oldZoom - newZoom);
  redrawMapCustomer();
}
