var line_objectResizingActive = false;
var line_objectMovingActive = false;
var line_startXResize = 0;
var line_startYResize = 0;
var line_startXMoving = 0;
var line_startYMoving = 0;
var line_resizeDistance = 0;
var line_movingDistance = 0;

function initLineObjectOptions() {
    var selectedObject = objectData[selectedObjectIndex];
    if (selectedObject.rotation == 0) {
        addLineResizeButton();
        addLineMoveIcon();
    }
}

function addLineResizeButton() {
    var selectedObject = objectData[selectedObjectIndex];

    var length = getLength(selectedObject.length);

    var xPos = getXPos(selectedObject.x) + length - 15;
    var yPos = getYPos(selectedObject.y) - 15;

    var image = new Image();
    image.onload = function () {
        contextAdmin.drawImage(image, xPos, yPos, 30, 30);
    };
    image.src = 'assets/modules/location_manager_admin/images/resize_icon.png';

    canvasAdmin.addEventListener("mousedown", clickOnLineResizeButton);
    canvasAdmin.addEventListener('mousemove', resizeLineObject);
    canvasAdmin.addEventListener('mouseup', stopLineResizing);
}

function clickOnLineResizeButton(event) {
    if (selectedObjectIndex !== -1) {
        var selectedObject = objectData[selectedObjectIndex];
        var xPos = getXPos(selectedObject.x) + selectedObject.length * zoomingFactor - 15;
        var yPos = getYPos(selectedObject.y) - 15;

        if (event.offsetX > xPos && event.offsetX < xPos + 30) {
            if (event.offsetY > yPos && event.offsetY < yPos + 30) {
                mapZoomingActive = false;
                mapMovingActive = false;
                line_objectResizingActive = true;
                line_startXResize = event.offsetX;
                line_startYResize = event.offsetY;
                line_resizeDistance = 0;
            }
        }
    }
}

function resizeLineObject(event) {
    if (line_objectResizingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        var selectedObject = objectData[selectedObjectIndex];

        selectedObject.length += dx / zoomingFactor;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function stopLineResizing() {
    line_objectResizingActive = false;
    mapZoomingActive = true;
    mapMovingActive = true;
}

function addLineMoveIcon() {
    var selectedObject = objectData[selectedObjectIndex];

    var length = getLength(selectedObject.length);

    var xPos = getXPos(selectedObject.x) + length / 2 - 15;
    var yPos = getYPos(selectedObject.y) - 15;


    var image = new Image();
    image.onload = function () {
        contextAdmin.drawImage(image, xPos, yPos, 30, 30);
    };
    image.src = 'assets/modules/location_manager_admin/images/move_icon.png';

    canvasAdmin.addEventListener("mousedown", clickOnLineMoveIcon);
    canvasAdmin.addEventListener('mousemove', moveLineObject);
    canvasAdmin.addEventListener('mouseup', stopLineObjectMoving);
}

function clickOnLineMoveIcon(event) {
    if (selectedObjectIndex !== -1) {
        var selectedObject = objectData[selectedObjectIndex];
        var xPos = getXPos(selectedObject.x) + selectedObject.length / 2 * zoomingFactor - 15;
        var yPos = getYPos(selectedObject.y) - 15;

        if (event.offsetX > xPos && event.offsetX < xPos + 30) {
            if (event.offsetY > yPos && event.offsetY < yPos + 30) {
                mapZoomingActive = false;
                mapMovingActive = false;
                line_objectMovingActive = true;
                line_startXMoving = event.offsetX;
                line_startYMoving = event.offsetY;
                line_movingDistance = 0;
            }
        }
    }
}

function moveLineObject(event) {
    if (line_objectMovingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        var selectedObject = objectData[selectedObjectIndex];

        selectedObject.x += dx / zoomingFactor;
        selectedObject.y += dy / zoomingFactor;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function stopLineObjectMoving() {
    line_objectMovingActive = false;
    mapZoomingActive = true;
    mapMovingActive = true;
}
