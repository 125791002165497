function drawSection(section, isSelectedObject) {
  var xPos = getXPos(section.x);
  var yPos = getYPos(section.y);

  var width = getWidth(section.width);
  var height = getHeight(section.height);

  initRotation(xPos, yPos, section.rotation);

  if (isSelectedObject) {
    contextAdmin.lineWidth = 2;
    contextAdmin.strokeStyle = 'red';
    contextAdmin.strokeRect(xPos, yPos, width, height);

    drawFrontArrow(xPos, yPos, width, height);
  } else if (section.hasBorder) {
    contextAdmin.lineWidth = section.borderWidth;
    contextAdmin.strokeStyle = section.borderColor;
    contextAdmin.strokeRect(xPos, yPos, width, height);
  }

  if (section.filled) {
    contextAdmin.fillStyle = section.fillColor;
    contextAdmin.fillRect(xPos, yPos, width, height);
  }

  if (!section.freeSeatSelection) {
    var seatSize = section.seatSize * zoomingFactor;
    var rowSpace = (height - section.rows.length * seatSize) / section.rows.length;

    for (var i = 0; i < section.rows.length; i++) {
      var addToCount = section.showRowNumbers ? 1 : 0;
      var rowYPos = yPos + 0.5 * rowSpace + i * rowSpace + (i + 0.5) * seatSize;
      var seatSpace = (width - (section.rows[i].seats.length + addToCount) * seatSize) / (section.rows[i].seats.length + addToCount);

      for (var j = addToCount; j < section.rows[i].seats.length + addToCount; j++) {
        var seatXPos = xPos + 0.5 * seatSpace + (j + addToCount) * seatSpace + (j + 0.5) * seatSize;
        drawSeat(seatXPos, rowYPos, seatSize, section.seatColor);
      }

      if (section.showRowNumbers) {
        drawRowNumber(seatSize, i + 1, xPos + 0.5 * seatSpace + 0.25 * seatSize, rowYPos + 0.25 * seatSize);
      }
    }
  }

  endRotation();
}

function drawSeat(x, y, size, color) {
  contextAdmin.beginPath();
  contextAdmin.arc(x, y, size / 2, 0, 2 * Math.PI);
  contextAdmin.fillStyle = color;
  contextAdmin.fill();
}

function drawRowNumber(seatSize, number, xPos, yPos) {
  var textSize = seatSize * 0.8;
  contextAdmin.font = textSize + 'px Arial';
  contextAdmin.fillStyle = 'black';
  contextAdmin.strokeStyle = 'black';
  contextAdmin.fillText(number, xPos, yPos);
  contextAdmin.strokeText(number, xPos, yPos);
}

function drawFrontArrow(xPos, yPos, width, height) {
  // Draw the line
  contextAdmin.beginPath();
  contextAdmin.moveTo(xPos - 15, yPos);
  contextAdmin.lineTo(xPos - 15, yPos + height);
  contextAdmin.stroke();

  // Draw the arrow
  contextAdmin.beginPath();
  contextAdmin.moveTo(xPos - 20, yPos + 8);
  contextAdmin.lineTo(xPos - 15, yPos);
  contextAdmin.lineTo(xPos - 10, yPos + 8);
  contextAdmin.stroke();
}
