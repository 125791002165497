var rect_objectResizingActive = false;
var rect_objectMovingActive = false;
var rect_startXResize = 0;
var rect_startYResize = 0;
var rect_startXMoving = 0;
var rect_startYMoving = 0;
var rect_resizeDistance = 0;
var rect_movingDistance = 0

function initRectagleObjectOptions() {
    var selectedObject = objectData[selectedObjectIndex];
    if (selectedObject.rotation == 0) {
        addRectangleResizeButton();
        addRectangleMoveIcon();
    }
}

function addRectangleResizeButton() {
    var selectedObject = objectData[selectedObjectIndex];

    var width = getWidth(selectedObject.width);
    var height = getHeight(selectedObject.height);

    var xPos = getXPos(selectedObject.x) + width - 15;
    var yPos = getYPos(selectedObject.y) + height - 15;

    var image = new Image();
    image.onload = function () {
        contextAdmin.drawImage(image, xPos, yPos, 30, 30);
    };
    image.src = 'assets/modules/location_manager_admin/images/resize_icon.png';

    canvasAdmin.addEventListener("mousedown", clickOnRectangleResizeButton);
    canvasAdmin.addEventListener('mousemove', resizeRectangleObject);
    canvasAdmin.addEventListener('mouseup', stopRectangleResizing);
}

function clickOnRectangleResizeButton(event) {
    if (selectedObjectIndex !== -1) {
        var selectedObject = objectData[selectedObjectIndex];

        var width = getWidth(selectedObject.width);
        var height = getHeight(selectedObject.height);

        var xPos = getXPos(selectedObject.x) + width - 15;
        var yPos = getYPos(selectedObject.y) + height - 15;

        if (event.offsetX > xPos && event.offsetX < xPos + 30) {
            if (event.offsetY > yPos && event.offsetY < yPos + 30) {
                mapZoomingActive = false;
                mapMovingActive = false;
                rect_objectResizingActive = true;
                rect_startXResize = event.offsetX;
                rect_startYResize = event.offsetY;
                rect_resizeDistance = 0;
            }
        }
    }
}

function resizeRectangleObject(event) {
    if (rect_objectResizingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        var selectedObject = objectData[selectedObjectIndex];

        selectedObject.width += dx / zoomingFactor;
        selectedObject.height -= dy / zoomingFactor;

        selectedObject.y += dy / zoomingFactor;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function stopRectangleResizing() {
    rect_objectResizingActive = false;
    mapZoomingActive = true;
    mapMovingActive = true;
}

function addRectangleMoveIcon() {
    var selectedObject = objectData[selectedObjectIndex];

    var width = getWidth(selectedObject.width);

    var xPos = getXPos(selectedObject.x) + width / 2 - 15;
    var yPos = getYPos(selectedObject.y) - 15;


    var image = new Image();
    image.onload = function () {
        contextAdmin.drawImage(image, xPos, yPos, 30, 30);
    };
    image.src = 'assets/modules/location_manager_admin/images/move_icon.png';

    canvasAdmin.addEventListener("mousedown", clickOnRectangleMoveIcon);
    canvasAdmin.addEventListener('mousemove', moveRectangleObject);
    canvasAdmin.addEventListener('mouseup', stopRectangleObjectMoving);
}

function clickOnRectangleMoveIcon(event) {
    if (selectedObjectIndex !== -1) {
        var selectedObject = objectData[selectedObjectIndex];
        var xPos = getXPos(selectedObject.x) + selectedObject.width / 2 * zoomingFactor - 15;
        var yPos = getYPos(selectedObject.y) - 15;

        if (event.offsetX > xPos && event.offsetX < xPos + 30) {
            if (event.offsetY > yPos && event.offsetY < yPos + 30) {
                mapZoomingActive = false;
                mapMovingActive = false;
                rect_objectMovingActive = true;
                rect_startXMoving = event.offsetX;
                rect_startYMoving = event.offsetY;
                rect_movingDistance = 0;
            }
        }
    }
}

function moveRectangleObject(event) {
    if (rect_objectMovingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        var selectedObject = objectData[selectedObjectIndex];

        selectedObject.x += dx / zoomingFactor;
        selectedObject.y += dy / zoomingFactor;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function stopRectangleObjectMoving() {
    rect_objectMovingActive = false;
    mapZoomingActive = true;
    mapMovingActive = true;
}
