function redrawMapCustomer() {
  context.clearRect(0, 0, canvas.width, canvas.height);

  for (var i = 0; i < objectData.length; i++) {
    drawObjectCustomer(objectData[i], i == selectedObjectIndex);
  }
}

function drawObjectCustomer(object, isSelectedObject) {
  switch (object.type) {
    case 'RECTANGLE':
      drawRectangleCustomer(object, isSelectedObject);
      break;
    case 'LINE':
      drawLineCustomer(object, isSelectedObject);
      break;
    case 'SECTION':
      drawSectionCustomer(object, isSelectedObject);
      break;
    case 'TEXT':
      drawTextCustomer(object, isSelectedObject);
      break;
  }
}
