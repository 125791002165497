var section_objectResizingActive = false;
var section_objectMovingActive = false;
var section_startXResize = 0;
var section_startYResize = 0;
var section_startXMoving = 0;
var section_startYMoving = 0;
var section_resizeDistance = 0;
var section_movingDistance = 0;

function initSectionObjectOptions() {
    var selectedObject = objectData[selectedObjectIndex];
    if (selectedObject.rotation == 0) {
        addSectionResizeButton();
        addSectionMoveIcon();
    }
}

function addSectionResizeButton() {
    var selectedObject = objectData[selectedObjectIndex];

    var width = getWidth(selectedObject.width);
    var height = getHeight(selectedObject.height);

    var xPos = getXPos(selectedObject.x) + width - 15;
    var yPos = getYPos(selectedObject.y) + height - 15;

    var image = new Image();
    image.onload = function () {
        contextAdmin.drawImage(image, xPos, yPos, 30, 30);
    };
    image.src = 'assets/modules/location_manager_admin/images/resize_icon.png';

    canvasAdmin.addEventListener("mousedown", clickOnSectionResizeButton);
    canvasAdmin.addEventListener('mousemove', resizeSectionObject);
    canvasAdmin.addEventListener('mouseup', stopSectionResizing);
}

function clickOnSectionResizeButton(event) {
    if (selectedObjectIndex !== -1) {
        var selectedObject = objectData[selectedObjectIndex];

        var width = getWidth(selectedObject.width);
        var height = getHeight(selectedObject.height);

        var xPos = getXPos(selectedObject.x) + width - 15;
        var yPos = getYPos(selectedObject.y) + height - 15;

        if (event.offsetX > xPos && event.offsetX < xPos + 30) {
            if (event.offsetY > yPos && event.offsetY < yPos + 30) {
                mapZoomingActive = false;
                mapMovingActive = false;
                section_objectResizingActive = true;
                section_startXResize = event.offsetX;
                section_startYResize = event.offsetY;
                section_resizeDistance = 0;
            }
        }
    }
}

function resizeSectionObject(event) {
    if (section_objectResizingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        var selectedObject = objectData[selectedObjectIndex];

        selectedObject.height += dy / zoomingFactor;
        selectedObject.width += dx / zoomingFactor;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function stopSectionResizing() {
    section_objectResizingActive = false;
    mapZoomingActive = true;
    mapMovingActive = true;
}

function addSectionMoveIcon() {
    var selectedObject = objectData[selectedObjectIndex];

    var width = getWidth(selectedObject.width);

    var xPos = getXPos(selectedObject.x) + width / 2 - 15;
    var yPos = getYPos(selectedObject.y) - 15;


    var image = new Image();
    image.onload = function () {
        contextAdmin.drawImage(image, xPos, yPos, 30, 30);
    };
    image.src = 'assets/modules/location_manager_admin/images/move_icon.png';

    canvasAdmin.addEventListener("mousedown", clickOnSectionMoveIcon);
    canvasAdmin.addEventListener('mousemove', moveSectionObject);
    canvasAdmin.addEventListener('mouseup', stopSectionObjectMoving);
}

function clickOnSectionMoveIcon(event) {
    if (selectedObjectIndex !== -1) {
        var selectedObject = objectData[selectedObjectIndex];
        var xPos = getXPos(selectedObject.x) + selectedObject.width / 2 * zoomingFactor - 15;
        var yPos = getYPos(selectedObject.y) - 15;

        if (event.offsetX > xPos && event.offsetX < xPos + 30) {
            if (event.offsetY > yPos && event.offsetY < yPos + 30) {
                mapZoomingActive = false;
                mapMovingActive = false;
                section_objectMovingActive = true;
                section_startXMoving = event.offsetX;
                section_startYMoving = event.offsetY;
                section_movingDistance = 0;
            }
        }
    }
}

function moveSectionObject(event) {
    if (section_objectMovingActive) {
        const currentX = event.offsetX;
        const dx = currentX - startXAdmin;
        const currentY = event.offsetY;
        const dy = currentY - startYAdmin;

        var selectedObject = objectData[selectedObjectIndex];

        selectedObject.x += dx / zoomingFactor;
        selectedObject.y += dy / zoomingFactor;

        redrawMap();

        startXAdmin = currentX;
        startYAdmin = currentY;
    }
}

function stopSectionObjectMoving() {
    section_objectMovingActive = false;
    mapZoomingActive = true;
    mapMovingActive = true;
}
