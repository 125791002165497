function redrawMap() {
  contextAdmin.clearRect(0, 0, canvasAdmin.width, canvasAdmin.height);

  for (var i = 0; i < objectData.length; i++) {
    if (i !== selectedObjectIndex) {
      //Don't draw selected object
      drawObject(objectData[i], false);
    }
  }

  //If object selected => Draw selected object after anything else to not accidentally draw over object indicators
  if (selectedObjectIndex !== -1) {
    drawObject(objectData[selectedObjectIndex], true);
  }

  if (!isPreview) {
    initObjectSelectionMenu();

    initObjectInfoSection();
  }
}

function drawObject(object, isSelectedObject) {
  switch (object.type) {
    case 'RECTANGLE':
      drawRectangle(object, isSelectedObject);
      if (isSelectedObject && !isPreview) {
        initRectagleObjectOptions();
      }
      break;
    case 'LINE':
      drawLine(object, isSelectedObject);
      if (isSelectedObject && !isPreview) {
        initLineObjectOptions();
      }
      break;
    case 'SECTION':
      drawSection(object, isSelectedObject);
      if (isSelectedObject && !isPreview) {
        initSectionObjectOptions();
      }
      break;
    case 'TEXT':
      drawText(object, isSelectedObject);
      if (isSelectedObject && !isPreview) {
        initTextObjectOptions();
      }
      break;
  }
}

function initObjectSelectionMenu() {
  if (objectData.length > 0) {
    document.getElementById('objectMenuContainer').style.visibility = 'visible';
  }
  mapObjectMenuContainerAdmin.innerHTML = '';
  for (var i = 0; i < objectData.length; i++) {
    addObjectMenu(objectData[i].name, i);
  }
}

function addObjectMenu(name, i) {
  const div = document.createElement('div');
  const classes =
    'p-4 border-b hover:bg-location-manager-menu-hover cursor-pointer' +
    (i === selectedObjectIndex ? ' bg-location-manager-selected-object' : '');
  div.classList = [classes];

  const p = document.createElement('p');
  p.innerHTML = name;
  p.id = 'object-menu-entry-' + i;
  p.addEventListener('click', function () {
    selectObject(i);
  });
  div.appendChild(p);
  mapObjectMenuContainerAdmin.appendChild(div);
  if (i === selectedObjectIndex) {
    p.classList = ['objectMenuEntry active'];
  } else {
    p.classList = ['objectMenuEntry'];
  }
}

function selectObject(i) {
  selectedObjectIndex = i;
  redrawMap();
}
