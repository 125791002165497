function drawTextCustomer(text, isSelectedObject) {
  var xPos = getXPos(text.x);
  var yPos = getYPos(text.y);

  initRotationCustomer(xPos, yPos, text.rotation);

  var fontSize = text.fontSize * zoomingFactor;

  context.font = fontSize + 'px Arial';
  context.fillStyle = isSelectedObject ? 'red' : text.color;
  context.strokeStyle = isSelectedObject ? 'red' : text.color;

  context.fillText(text.text, xPos, yPos);

  context.strokeText(text.text, xPos, yPos);

  endRotationCustomer();
}
