function getXPos(x){
    return mapPositionX + x * zoomingFactor;
}

function getYPos(y){
    return mapPositionY + y * zoomingFactor;
}

function getWidth(width){
    return width * zoomingFactor;
}

function getHeight(height){
    return height * zoomingFactor;
}

function getLength(length){
    return length * zoomingFactor;
}

function radiansToDegrees(radians) {
    return (radians * 180) / Math.PI
}

function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180
}

function initRotation(pivotX, pivotY, degrees){
    contextAdmin.save();
    contextAdmin.translate(pivotX, pivotY);
    contextAdmin.rotate(degreesToRadians(degrees));
    contextAdmin.translate(-pivotX, -pivotY);
}

function endRotation(){
    contextAdmin.restore();
}
