let isMousePressed = false;
let startX = 0;
let startY = 0;
let distance = 0;

function initMovingCustomer() {
  canvas.addEventListener('mousedown', handleMouseDownCustomer);
  canvas.addEventListener('touchstart', handleMouseDownCustomer);
  canvas.addEventListener('mousemove', handleMouseMoveCustomer);
  canvas.addEventListener('touchmove', handleTouchMoveCustomer);
  canvas.addEventListener('mouseup', handleMouseUpCustomer);
  canvas.addEventListener('touchend', handleMouseUpCustomer);
}

function handleMouseDownCustomer(event) {
  mapHasBeenMoved = false;
  isMousePressed = true;
  distance = 0;

  if (event.button === 0) {
    startX = event.offsetX;
    startY = event.offsetY;
  } else if (event.type === 'touchstart') {
    startX = event.touches[0].clientX;
    startY = event.touches[0].clientY;
  }
}

function handleMouseMoveCustomer(event) {
  if (isMousePressed && mapMovingActive) {
    mapHasBeenMoved = true;
    const currentX = event.offsetX;
    const dx = currentX - startX;
    const currentY = event.offsetY;
    const dy = currentY - startY;

    mapPositionX += dx;
    mapPositionY += dy;

    redrawMapCustomer();

    startX = currentX;
    startY = currentY;
  }
}

function handleTouchMoveCustomer(event) {
  mapHasBeenMoved = true;
  const currentX = event.touches[0].clientX;
  const dx = currentX - startX;
  const currentY = event.touches[0].clientY;
  const dy = currentY - startY;

  mapPositionX += dx;
  mapPositionY += dy;

  redrawMapCustomer();

  startX = currentX;
  startY = currentY;
}

function handleMouseUpCustomer(event) {
  if ((event.button === 0 || event.type === 'touchend') && isMousePressed) {
    isMousePressed = false;
  }
}
