function drawRectangle(rectangle, isSelectedObject) {
  var xPos = getXPos(rectangle.x);
  var yPos = getYPos(rectangle.y);
  var width = getWidth(rectangle.width);
  var height = getHeight(rectangle.height);

  initRotation(xPos, yPos, rectangle.rotation);

  if (rectangle.filled) {
    contextAdmin.fillStyle = rectangle.fillColor;
    contextAdmin.fillRect(xPos, yPos, width, height);
  }

  if (rectangle.hasBorder && !isSelectedObject) {
    contextAdmin.strokeStyle = rectangle.borderColor;
    contextAdmin.lineWidth = rectangle.borderWidth;
    contextAdmin.strokeRect(xPos, yPos, width, height);
  } else if (isSelectedObject) {
    contextAdmin.strokeStyle = "red";
    contextAdmin.lineWidth = 2;
    contextAdmin.strokeRect(xPos, yPos, width, height);
  }

  endRotation();
}
