var mapPositionX = 0;
var mapPositionY = 0;

let canvasCenterXAdmin = 384;
let canvasCenterYAdmin = 300;

var zoomingFactor = 1;
const zoomSpeedAdmin = 1.1;

var objectData = [];

var selectedObjectIndex = -1;
var copiedElementIndex = -1;

var canvasAdmin;
var contextAdmin;

var mapObjectMenuContainerAdmin;

var contextMenuAdmin;

var mapMovingActive = true;
var mapZoomingActive = true;

var isPreview = false;

var mapDefaults = {
  x: 0,
  y: 0,
  zoom: 1
};

var priceConfigs = [];

var updateSelectedPriceConfig;

function updateData(data) {
  objectData = data;
  redrawMap();
}

function initLocationManagerAdmin(initialData, preview, defaultSettings, priceCons, updatePriceConfig) {
  priceConfigs = priceCons;
  updateSelectedPriceConfig = updatePriceConfig;

  if (defaultSettings) {
    mapDefaults = defaultSettings;
    mapPositionX = defaultSettings.x;
    mapPositionY = defaultSettings.y;
    zoomingFactor = defaultSettings.zoom;
  }

  isPreview = preview;

  if (initialData.length === 0) {
    objectData = [getInitialSection(preview ? undefined : priceConfigs[0].id)];
  } else {
    objectData = initialData;
  }

  canvasAdmin = document.getElementById('sectionCanvasAdmin');
  contextAdmin = canvasAdmin.getContext('2d');
  mapObjectMenuContainerAdmin = document.getElementById('objectMenuContainer');
  contextMenuAdmin = document.getElementById('canvasContextMenu');

  canvasAdmin.width = canvasAdmin.offsetWidth;
  canvasAdmin.height = canvasAdmin.offsetHeight;
  canvasCenterXAdmin = canvasAdmin.offsetWidth / 2;
  canvasCenterYAdmin = canvasAdmin.offsetHeight / 2;

  if (!isPreview) {
    selectedObjectIndex = 0;

    initMenu();
    initButtonFunctions();

    document.getElementById('objectMenuContainer').addEventListener('contextmenu', e => {
      e.preventDefault();
    });

    document.getElementById('objectInfoContainer').addEventListener('contextmenu', e => {
      e.preventDefault();
    });
  } else {
    selectedObjectIndex = -1;
  }

  initMoving();
  initZooming();
  redrawMap();
}

function getMapData() {
  return objectData;
}

function getMapDefaults() {
  return mapDefaults;
}
