var canvas;

var context;

var mapPositionX = 220;
var mapPositionY = 10;

var zoomingFactor = 1;
const zoomSpeed = 1.1;

var objectData = [];

var selectedObjectIndex = -1;
var copiedElementIndex = -1;

var mapMovingActive = true;
var mapZoomingActive = true;

var mapHasBeenMoved = false;

var maxTicketsForEvent = 0;

var seatSelectedCallback;
var sectionSelectedCallback;
var selectedTickets;

var maxTicketsReachedCallback;
var leaveSingleSeatsCallback;

function updateMapData(mapData) {
  objectData = mapData;
  redrawMapCustomer();
}

function initLocationManagerCustomer(
  mapData,
  maxTickets,
  defaultX,
  defaultY,
  defaultZoom,
  seatSelected,
  sectionSelected,
  selectedTicketsAmount,
  maxTicketsReached,
  leaveSingleSeats
) {
  canvas = document.getElementById('sectionCanvas');
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;

  window.addEventListener('resize', windowResized);

  context = canvas.getContext('2d');

  objectData = mapData;

  maxTicketsForEvent = maxTickets;

  mapPositionX = defaultX;
  mapPositionY = defaultY;
  zoomingFactor = defaultZoom;

  initMovingCustomer();
  initZoomingCustomer();
  redrawMapCustomer();
  preventCanvasRightClick();

  seatSelectedCallback = seatSelected;
  sectionSelectedCallback = sectionSelected;
  selectedTickets = selectedTicketsAmount;
  maxTicketsReachedCallback = maxTicketsReached;
  leaveSingleSeatsCallback = leaveSingleSeats;
  initSelection();
}

function windowResized() {
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;
  redrawMapCustomer();
}

function preventCanvasRightClick() {
  canvas.addEventListener('contextmenu', event => {
    event.preventDefault();
  });
}

function updateSelectedTicketsNumber(selectedTicketsAmount) {
  selectedTickets = selectedTicketsAmount;
}
