var menuPosX = 0;
var menuPosY = 0;

function initMenu() {
  canvasAdmin.addEventListener('contextmenu', handleContextMenu);
  document.getElementById('addRectangle').addEventListener('click', addRectangle);
  document.getElementById('addSection').addEventListener('click', addSection);
  document.getElementById('addLine').addEventListener('click', addLine);
  document.getElementById('addText').addEventListener('click', addText);
  document.getElementById('pasteObject').addEventListener('click', pasteSelectedObject);
}

function handleContextMenu(event) {
  event.preventDefault();

  if (!isPreview) {
    menuPosX = event.offsetX;
    menuPosY = event.offsetY;

    var xPos = event.clientX + document.documentElement.scrollLeft;
    var yPos = event.clientY + document.documentElement.scrollTop;

    contextMenuAdmin.style.left = xPos + 'px';
    contextMenuAdmin.style.top = yPos + 'px';
    contextMenuAdmin.style.display = 'block';
  }
}

document.addEventListener('click', hideCustomMenu);

function hideCustomMenu() {
  if (contextMenuAdmin) {
    contextMenuAdmin.style.display = 'none';
  }
}

function addRectangle() {
  var x = (menuPosX - mapPositionX) / zoomingFactor;
  var y = (menuPosY - mapPositionY) / zoomingFactor;

  objectData.push({
    type: 'RECTANGLE',
    name: 'Rectangle',
    x: x,
    y: y,
    rotation: 0,
    width: 300,
    height: 200,
    filled: false,
    hasBorder: true,
    fillColor: 'white',
    borderWidth: 2,
    borderColor: 'black'
  });

  selectedObjectIndex = objectData.length - 1;
  redrawMap();
}

function addLine() {
  var x = (menuPosX - mapPositionX) / zoomingFactor;
  var y = (menuPosY - mapPositionY) / zoomingFactor;

  objectData.push({
    type: 'LINE',
    name: 'Line',
    x: x,
    y: y,
    rotation: 0,
    length: 200,
    width: 2,
    color: 'black'
  });

  selectedObjectIndex = objectData.length - 1;
  redrawMap();
}

function addText() {
  var x = (menuPosX - mapPositionX) / zoomingFactor;
  var y = (menuPosY - mapPositionY) / zoomingFactor;

  objectData.push({
    type: 'TEXT',
    name: 'Text',
    text: 'Text',
    x: x,
    y: y,
    rotation: 0,
    color: 'black',
    fontSize: 14
  });

  selectedObjectIndex = objectData.length - 1;
  redrawMap();
}

function addSection() {
  var section = getInitialSection(priceConfigs[0].id);

  var x = (menuPosX - mapPositionX) / zoomingFactor;
  var y = (menuPosY - mapPositionY) / zoomingFactor;

  section.x = x;
  section.y = y;

  objectData.push(section);

  selectedObjectIndex = objectData.length - 1;
  redrawMap();
}

function pasteSelectedObject() {
  if (copiedElementIndex !== -1) {
    var x = (menuPosX - mapPositionX) / zoomingFactor;
    var y = (menuPosY - mapPositionY) / zoomingFactor;

    var copiedArray = JSON.parse(JSON.stringify(objectData));
    var newObject = copiedArray[copiedElementIndex];

    newObject.x = x;
    newObject.y = y;

    objectData.push(newObject);
    selectedObjectIndex = objectData.length - 1;

    redrawMap();

    document.getElementById('pasteObject').style.display = 'none';
  }
}
