function initSelection(seatSelected) {
  canvas.addEventListener('click', selectSeat);
}

function selectSeat(event) {
  if (mapHasBeenMoved) {
    return;
  }
  var xClicked = event.offsetX;
  var yClicked = event.offsetY;

  objectData.forEach((object, i) => {
    if (object.type === 'SECTION') {
      const section = object;
      const xPos = getXPos(section.x);
      const yPos = getYPos(section.y);
      const width = getWidth(section.width);
      const height = getHeight(section.height);
      const rotation = section.rotation;
      const cosAlpha = Math.cos(degreesToRadians(-rotation));
      const sinAlpha = Math.sin(degreesToRadians(-rotation));
      const rotatedXClicked = (xClicked - xPos) * cosAlpha - (yClicked - yPos) * sinAlpha;
      const rotatedYClicked = (xClicked - xPos) * sinAlpha + (yClicked - yPos) * cosAlpha;
      if (0 <= rotatedXClicked && rotatedXClicked <= width && 0 <= rotatedYClicked && rotatedYClicked <= height) {
        if (object.freeSeatSelection) {
          if (selectedTickets === maxTicketsForEvent) {
            maxTicketsReachedCallback();
          } else {
            sectionSelectedCallback(object);
          }
        } else {
          const seatSize = section.seatSize * zoomingFactor;
          const rowSpace = (height - section.rows.length * seatSize) / section.rows.length;
          for (var j = 0; j < section.rows.length; j++) {
            var rowYPos = 0.5 * rowSpace + j * rowSpace + (j + 0.5) * seatSize;
            const seatSpace = (width - section.rows[j].seats.length * seatSize) / section.rows[j].seats.length;
            for (var k = 0; k < section.rows[j].seats.length; k++) {
              var seatXPos = 0.5 * seatSpace + k * seatSpace + (k + 0.5) * seatSize;
              const distance = (rotatedXClicked - seatXPos) ** 2 + (rotatedYClicked - rowYPos) ** 2;
              if (distance < (seatSize / 2) ** 2) {
                if (section.rows[j].seats[k].available) {
                  if (!section.rows[j].seats[k].selected && selectedTickets === maxTicketsForEvent) {
                    maxTicketsReachedCallback();
                  } else {
                    if (!section.rows[j].seats[k].selected) {
                      if (singleSeatLeftAfterSelected(section, j, k)) {
                        leaveSingleSeatsCallback(true);
                        section.rows[j].seats[k].selected = !section.rows[j].seats[k].selected;
                        seatSelectedCallback(section.rows[j].seats[k], section.rows[j], section);
                        redrawMapCustomer();
                      } else {
                        leaveSingleSeatsCallback(false);
                        section.rows[j].seats[k].selected = !section.rows[j].seats[k].selected;
                        seatSelectedCallback(section.rows[j].seats[k], section.rows[j], section);
                        redrawMapCustomer();
                      }
                    } else {
                      if (singleSeatLeftAfterUnSelected(section, j, k)) {
                        leaveSingleSeatsCallback(true);
                      } else {
                        leaveSingleSeatsCallback(false);
                      }
                      section.rows[j].seats[k].selected = !section.rows[j].seats[k].selected;
                      seatSelectedCallback(section.rows[j].seats[k]);
                      redrawMapCustomer();
                    }

                    return;
                  }
                }
              }
            }
          }
        }
      }
    }
  });
}

function singleSeatLeftAfterSelected(section, rowIndex, seatIndex) {
  var singleSeatLeft = false;

  if (seatIndex > 0) {
    //CHECK LEFT
    if (!section.rows[rowIndex].seats[seatIndex - 1].selected && section.rows[rowIndex].seats[seatIndex - 1].available) {
      if (seatIndex - 2 < 0) {
        return true;
      }

      singleSeatLeft =
        singleSeatLeft || section.rows[rowIndex].seats[seatIndex - 2].selected || !section.rows[rowIndex].seats[seatIndex - 2].available;
    }
  }

  if (seatIndex < section.rows[rowIndex].seats.length - 1) {
    //CHECK RIGHT
    if (!section.rows[rowIndex].seats[seatIndex + 1].selected && section.rows[rowIndex].seats[seatIndex + 1].available) {
      if (seatIndex + 2 > section.rows[rowIndex].seats.length - 1) {
        return true;
      }

      singleSeatLeft =
        singleSeatLeft || section.rows[rowIndex].seats[seatIndex + 2].selected || !section.rows[rowIndex].seats[seatIndex + 2].available;
    }
  }

  return singleSeatLeft;
}

function singleSeatLeftAfterUnSelected(section, rowIndex, seatIndex) {
  var singleSeatLeft = false;

  if (seatIndex > 0 && seatIndex < section.rows[rowIndex].seats.length - 1) {
    if (
      (section.rows[rowIndex].seats[seatIndex - 1].selected || !section.rows[rowIndex].seats[seatIndex - 1].available) &&
      (section.rows[rowIndex].seats[seatIndex + 1].selected || !section.rows[rowIndex].seats[seatIndex + 1].available)
    ) {
      return true;
    }
  }

  return singleSeatLeft;
}
