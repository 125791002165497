function drawSectionCustomer(section, isSelectedObject) {
  var xPos = getXPos(section.x);
  var yPos = getYPos(section.y);

  var width = getWidth(section.width);
  var height = getHeight(section.height);

  initRotationCustomer(xPos, yPos, section.rotation);

  if (isSelectedObject) {
    context.lineWidth = 2;
    context.strokeStyle = 'red';
    context.strokeRect(xPos, yPos, width, height);
  } else if (section.hasBorder) {
    context.lineWidth = section.borderWidth;
    context.strokeStyle = section.borderColor;
    context.strokeRect(xPos, yPos, width, height);
  }

  if (section.filled) {
    context.fillStyle = section.availableSeats > 0 ? section.fillColor : 'lightgray';
    context.fillRect(xPos, yPos, width, height);
  }

  if (!section.freeSeatSelection) {
    var seatSize = section.seatSize * zoomingFactor;
    var rowSpace = (height - section.rows.length * seatSize) / section.rows.length;

    for (var i = 0; i < section.rows.length; i++) {
      var rowYPos = yPos + 0.5 * rowSpace + i * rowSpace + (i + 0.5) * seatSize;
      var seatSpace = (width - section.rows[i].seats.length * seatSize) / section.rows[i].seats.length;

      for (var j = 0; j < section.rows[i].seats.length; j++) {
        var seatXPos = xPos + 0.5 * seatSpace + j * seatSpace + (j + 0.5) * seatSize;
        drawSeatCustomer(seatXPos, rowYPos, seatSize, section.rows[i].seats[j].available ? section.seatColor : 'gray');
        if (section.rows[i].seats[j].selected) {
          drawSeatBorderCustomer(seatXPos, rowYPos, seatSize);
        }
      }
    }
  }

  endRotationCustomer();
}

function drawSeatCustomer(x, y, size, color) {
  context.beginPath();
  context.arc(x, y, size / 2, 0, 2 * Math.PI);
  context.fillStyle = color;
  context.fill();
}

function drawSeatBorderCustomer(x, y, size) {
  context.beginPath();
  context.arc(x, y, size / 2 + 4, 0, 2 * Math.PI);
  context.strokeStyle = 'red';
  context.lineWidth = 2;
  context.stroke();
}
